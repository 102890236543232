import React, { useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { userHint } from '@2ndmarket/services';
import {
  validateCpf,
  validateCnpj,
  yupPt,
  HttpStatus,
  HttpError,
  Masks,
} from '@2ndmarket/helpers';

import {
  Icon,
  MaskedInput,
  themes,
} from '@2ndmarket/components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Loader, ModalDialog, Routine } from '../../components';
import Copyright from '../../components/Copyright/Copyright';
import Recovered from './Recovered';

const EmailRecover = () => {
  const router = useRouter();

  const [firstPage, setFirstPage] = useState(true);
  const [email, setEmail] = useState('');

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [documentMask, setDocumentMask] = useState('');

  const schema = yupPt.object().shape({
    cpf_cnpj: yupPt
      .string()
      .test('cpf_cnpj', '', (value, { createError }) => {
        if (value === '' || value === undefined) {
          return createError({
            message: 'Documento é obrigatório',
          });
        }

        if (value.toString().length <= 14) {
          if (!validateCpf(value.toString())) {
            return createError({
              message: 'Documento CPF inválido.',
            });
          }
        } else {
          if (!validateCnpj(value.toString())) {
            return createError({
              message: 'Documento CNPJ inválido.',
            });
          }
        }

        return true;
      }),
  });

  const defaultValues = {
    cpf_cnpj: '',
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: any) {
    const document = data.cpf_cnpj.replace(/\D/g, '');
    setLoaderState(true);
    userHint(document)
      .then((data: any) => {
        setFirstPage(false);
        setEmail(data);
      })
      .catch((error: HttpError) => {
        if (
          error.status ==
          HttpStatus.ClientErrorUnprocessableEntity
        ) {
          for (let fieldError of error.errors) {
            setError(fieldError.field as any, {
              type: 'custom',
              message: fieldError.message,
            });
          }
        } else {
          setShowErrorModal(true);
          setErrorModalMessage(error.error);
        }
      })
      .finally(() => {
        setLoaderState(false);
      });
  }

  function ChangeMask(value: string) {
    if (!value) {
      return;
    }
    if (value.replace(/\D/g, '').length < 12) {
      setDocumentMask(Masks.CPF_CNPJ);
    } else {
      setDocumentMask(Masks.CNPJ);
    }
  }

  return (
    <>
      <Grid container component="main" sx={{ height: '100svh' }}>
        <Routine />
        <Grid
          item
          md={5}
          sm={9}
          xs={12}
          height="100%"
          display="flex"
          paddingTop={8}
          position="relative"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ backgroundColor: 'background.neutral' }}
        >
          {loaderState ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Loader loaderState={loaderState} />
            </Box>
          ) : firstPage ? (
            <Box paddingX={3}>
              <IconButton
                onClick={() => router.back()}
                sx={{ ml: -1 }}
              >
                <Icon
                  rotate="180deg"
                  name="arrow-alternative"
                  color={themes.authentication.palette.grey[600]}
                />
              </IconButton>
              <Typography
                mb={2}
                mt={1}
                component="h2"
                variant="h2"
                color="text.primary"
              >
                Recupere seu e-mail
              </Typography>
              <Typography
                mb={4}
                component="p"
                variant="body1"
                color="text.secondary"
              >
                Digite seu CPF ou CPNJ para recuperar o e-mail
                com o qual realizou o seu cadastro no
                ecossistema:
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  {...register('cpf_cnpj', { required: true })}
                  sx={{ marginBottom: 5 }}
                  fullWidth
                  label="CPF/CNPJ"
                  error={Boolean(errors.cpf_cnpj)}
                  helperText={
                    errors.cpf_cnpj && errors.cpf_cnpj.message
                  }
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      unmask: true,
                      mask: documentMask,
                      inputMode: 'numeric',
                    },
                  }}
                  onChange={e => ChangeMask(e.target.value)}
                />
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ marginBottom: 4 }}
                >
                  Recuperar e-mail
                </Button>
                <Typography
                  component="p"
                  align="center"
                  color="text.secondary"
                >
                  Lembrou do e-mail?{' '}
                  <NextLink href="/" passHref>
                    <Link color="primary.main">
                      Entre na sua conta
                    </Link>
                  </NextLink>
                </Typography>
              </form>
            </Box>
          ) : (
            <Box paddingX={3}>
              <Recovered
                email={email}
                setFirstPage={setFirstPage}
              />
            </Box>
          )}
          <Copyright />
        </Grid>
      </Grid>
      {showErrorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
};

export default EmailRecover;
