import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { userLogin } from '@2ndmarket/services';
import { EmailSentProps } from '@2ndmarket/types';
import { useLocalStorage } from '@2ndmarket/hooks';
import {
  Icon,
  MaskedInput,
  themes,
} from '@2ndmarket/components';
import {
  HttpError,
  LocalStorage,
  Masks,
} from '@2ndmarket/helpers';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Loader from '../../components/Loader';
import Copyright from '../../components/Copyright';
import ResendCode from '../../components/ResendCode';
import Products from '../../helper/products';
import { ModalDialog } from '../../components';

import { defaultValues, schema } from './formInfoEmail';

const EmailSent: React.FC<EmailSentProps> = ({
  email,
  setPage,
}) => {
  const router = useRouter();

  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState({});

  const [loader, setLoader] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [storedProduct] = useLocalStorage(
    LocalStorage.PRODUCT,
    '',
  );

  const [token, setToken] = useLocalStorage(
    LocalStorage.JWT,
    '',
  );

  const [userId] = useLocalStorage(LocalStorage.USER_ID, '');

  const selectedProduct = storedProduct
    ? storedProduct
    : Products.Bankai;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleDelete = () => {
    email = '';
    setPage('');
  };

  const childCount = (dataChild: object) => {
    const regressiveCounter = 2;
    const { timeToShow }: any = dataChild;
    setDisabled(dataChild);
    if (timeToShow <= regressiveCounter) {
      setCount(0);
    } else {
      setCount(timeToShow);
    }
  };

  useEffect(() => {
    childCount(disabled);
  }, [disabled]);

  const onSubmit = (formContent: any) => {
    setLoader(true);
    userLogin(userId, formContent.code)
      .then((data: { access_token: string }) => {
        setToken(data.access_token);
        window.location.href =
          selectedProduct.destination +
          `?user=${userId}&token=${data.access_token}`;
      })
      .catch((error: HttpError) => {
        setLoader(false);
        setShowErrorModal(true);
        setErrorModalMessage(error.error);
      });
  };

  return (
    <Grid
      item
      md={5}
      sm={9}
      xs={12}
      display="flex"
      paddingTop={8}
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      {loader ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Loader
            loaderState={loader}
            title="Autenticando código"
          />
        </Box>
      ) : (
        <Grid item paddingX={3}>
          <Box mb={1}>
            <IconButton
              onClick={() => router.push('/')}
              sx={{ ml: -1 }}
            >
              <Icon
                size={20}
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[600]}
              />
            </IconButton>
            <Typography
              color="text.primary"
              component="h2"
              variant="h2"
              mt={1}
            >
              Cadastro realizado!{' '}
              <Icon
                size={25}
                name="party-emoji"
                format={IconFormat.SVG}
              />
            </Typography>
            <Typography
              color="text.secondary"
              variant="body1"
              component="p"
              mt={1}
              mb={1}
            >
              Informe o código de acesso enviado para:
            </Typography>
            <Chip
              label={email.toLocaleLowerCase()}
              onDelete={() => handleDelete()}
              onClick={() => handleDelete()}
              deleteIcon={<Icon name="close" size={10} />}
              sx={{
                borderRadius: '2px',
                color:
                  themes.authentication.palette.primary.main,
                backgroundColor:
                  themes.authentication.palette.grey[200],
                '&:hover': {
                  backgroundColor:
                    themes.authentication.palette.grey[200],
                },
                '& .MuiChip-deleteIcon': {
                  padding: '0 8px',
                  color:
                    themes.authentication.palette.primary.main,
                  '&:hover': {
                    color:
                      themes.authentication.palette.primary.main,
                  },
                },
              }}
            />
            <Box
              mt={2}
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextField
                fullWidth
                autoFocus
                label="Código *"
                margin="normal"
                variant="outlined"
                autoComplete="off"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    unmask: true,
                    mask: Masks.CODE,
                    inputMode: 'numeric',
                  },
                }}
                {...register('code', { required: true })}
                error={Boolean(errors.code)}
                helperText={errors.code && errors.code.message}
              />
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Typography
                    mb={1}
                    component="p"
                    variant="body2"
                    color="text.secondary"
                  >
                    Verifique sua caixa de spam
                  </Typography>
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                sx={{
                  zIndex: 3,
                  marginTop: 4,
                  position: 'relative',
                }}
              >
                Continuar para {selectedProduct.name}
              </Button>
            </Box>
            <ResendCode email={email} setDisabled={childCount} />
          </Box>
        </Grid>
      )}

      <Copyright />

      {showErrorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </Grid>
  );
};

export default EmailSent;
