import { useState } from 'react';
import Grid from '@mui/material/Grid';

import { Routine, EmailStep, PasswordStep } from '../components';
import loginStep from '../components/EmailStep/loginStep';

const Login: React.FC = () => {
  const [stepCurrent, setStepCurrent] = useState(
    loginStep.provideEmail,
  );
  const [email, setEmail] = useState('');

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      {loginStep.provideEmail === stepCurrent && (
        <EmailStep
          setStep={setStepCurrent}
          setEmail={setEmail}
          email={email}
        />
      )}

      {loginStep.providePassword === stepCurrent && (
        <PasswordStep setStep={setStepCurrent} email={email} />
      )}
    </Grid>
  );
};

export default Login;
