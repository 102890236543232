import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState, ChangeEvent } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useLocalStorage } from '@2ndmarket/hooks';
import {
  Icon,
  themes,
  MaskedInput,
} from '@2ndmarket/components';
import {
  FormRegister,
  FormRegisterProps,
} from '@2ndmarket/types';

import {
  userRegister,
  userRequestToken,
} from '@2ndmarket/services';

import {
  Masks,
  Documents,
  HttpError,
  HttpStatus,
  LocalStorage,
  ExternalUrls,
  RegisterDocuments,
} from '@2ndmarket/helpers';

import {
  Loader,
  Copyright,
  ModalDialog,
} from '../../components';

import { schema, defaultValues } from './formInfoRegister';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

const Register: React.FC<FormRegisterProps> = ({
  setRegisterEmail,
  setPage,
}) => {
  const router = useRouter();

  const [loader, setLoader] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  let textInput = useRef(null);

  const [documentText, setDocumentText] = useState(
    'Nome e sobrenome *',
  );

  const [mask, setMask] = useState(Masks.CPF);
  const [documentType, setDocumentType] = useState(
    Documents.CPF,
  );

  const [userId, setUserId] = useLocalStorage(
    LocalStorage.USER_ID,
    '',
  );

  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleDocumentType = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const typeDocument = event.target.value;
    const cnpjText = 'Razão social';
    const cpfText = 'Nome e sobrenome';

    if (typeDocument === Documents.CNPJ) {
      setDocumentType(Documents.CNPJ);
      setDocumentText(cnpjText);
      setMask(Masks.CNPJ);
    } else {
      setDocumentType(Documents.CPF);
      setDocumentText(cpfText);
      setMask(Masks.CPF);
    }
  };

  const handleErrors = (error: HttpError) => {
    switch (error.status) {
      case HttpStatus.ClientErrorUnprocessableEntity:
        for (let fieldError of error.errors) {
          setError(fieldError.field as any, {
            type: 'custom',
            message: fieldError.message,
          });
        }
        break;
      case HttpStatus.ClientErrorConflict:
        setShowErrorModal(true);
        setErrorModalMessage(error.error);
    }
  };

  const onSubmit = async (data: FormRegister) => {
    setLoader(true);
    if (data.person_type === '') {
      data.person_type = Documents.CPF;
    }

    const formMail = data.email;
    const cpf_cnpj = data.cpf_cnpj.replace(/\D/g, '');

    userRegister(
      data.name,
      data.email,
      cpf_cnpj,
      data.person_type,
    )
      .then((data: FormRegister) => {
        setUserId(data.id);
        setRegisterEmail(data.email);
        userRequestToken(formMail)
          .then(() => {
            setPage('sent');
          })
          .catch((error: HttpError) => {
            handleErrors(error);
          })
          .finally(() => {
            setLoader(false);
          });
      })
      .catch((error: HttpError) => {
        handleErrors(error);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <Grid
      item
      md={5}
      sm={9}
      xs={12}
      display="flex"
      paddingTop={8}
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      {loader ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Loader
            loaderState={loader}
            title="Criando cadastro..."
          />
        </Box>
      ) : (
        <Grid paddingX={3}>
          <Box mb={3}>
            <IconButton
              onClick={() => router.push('/')}
              sx={{ ml: -1 }}
            >
              <Icon
                size={20}
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[600]}
              />
            </IconButton>
            <Typography
              color="text.primary"
              component="h2"
              variant="h2"
              mt={2}
            >
              Crie sua conta
            </Typography>
            <Typography
              color="text.secondary"
              variant="body1"
              component="p"
              mt={2}
            >
              Realize o seu cadastro e tenha{' '}
              <Typography
                component="span"
                variant="body1"
                color={
                  themes.authentication.palette.primary.main
                }
              >
                acesso aos produtos do ecossistema.
              </Typography>
              <br /> Preencha os seus dados abaixo:
            </Typography>
          </Box>
          <Box
            component="form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              select
              fullWidth
              margin="dense"
              variant="outlined"
              label="Tipo de cadastro"
              defaultValue={Documents.CPF}
              {...register('person_type', { required: true })}
              onChange={handleDocumentType}
            >
              {RegisterDocuments.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label={documentText}
              error={Boolean(errors.name)}
              inputProps={{ maxLength: 200 }}
              {...register('name', { required: true })}
              helperText={errors.name && errors.name.message}
            />
            <TextField
              fullWidth
              margin="dense"
              label="E-mail *"
              variant="outlined"
              error={Boolean(errors.email)}
              {...register('email', { required: true })}
              helperText={errors.email && errors.email.message}
              inputProps={{
                style: { textTransform: 'lowercase' },
                maxLength: 75,
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              {...register('cpf_cnpj', { required: true })}
              label={`N.º do ${documentType.toUpperCase()} *`}
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  inputMode: 'numeric',
                  unmask: true,
                  mask: mask,
                },
              }}
              inputRef={textInput}
              error={Boolean(errors.cpf_cnpj)}
              helperText={
                errors.cpf_cnpj && errors.cpf_cnpj.message
              }
            />
            <FormGroup sx={{ mt: 2 }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="policy"
                      {...register('policy', { required: true })}
                      checked={watch('policy')}
                      sx={{
                        color:
                          themes.authentication.palette
                            .grey[200],
                      }}
                      {...(errors.policy && {
                        sx: {
                          color:
                            themes.authentication.palette.error
                              .main,
                        },
                      })}
                    />
                  }
                  id="policy"
                  name="policy"
                  label={
                    <Typography
                      component="p"
                      variant="body1"
                      color="text.secondary"
                    >
                      Aceito a{' '}
                      <NextLink
                        href={ExternalUrls.PRIVACY_POLICY}
                        passHref
                      >
                        <Link target="_blank" variant="body1">
                          Política de privacidade
                        </Link>
                      </NextLink>
                    </Typography>
                  }
                />
                <FormHelperText error>
                  {errors.policy ? errors.policy.message : ''}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="terms"
                      {...register('terms', { required: true })}
                      checked={watch('terms')}
                      sx={{
                        color:
                          themes.authentication.palette
                            .grey[200],
                      }}
                      {...(errors.terms && {
                        sx: {
                          color:
                            themes.authentication.palette.error
                              .main,
                        },
                      })}
                    />
                  }
                  id="terms"
                  name="terms"
                  label={
                    <Typography
                      component="p"
                      variant="body1"
                      color="text.secondary"
                    >
                      Aceito os{' '}
                      <NextLink
                        href={ExternalUrls.TERMS_AND_CONDITIONS}
                        passHref
                      >
                        <Link target="_blank" variant="body1">
                          Termos de uso
                        </Link>
                      </NextLink>
                    </Typography>
                  }
                />
                <FormHelperText error>
                  {errors.terms ? errors.terms.message : ''}
                </FormHelperText>
              </FormControl>
            </FormGroup>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              sx={{ marginTop: 4 }}
            >
              Concluir cadastro
            </Button>
            <Typography
              padding={2}
              component="p"
              align="center"
              variant="body1"
              color="text.secondary"
            >
              Já possui cadastro?{' '}
              <NextLink href="/" passHref>
                <Link color="primary.main" variant="body1">
                  Entre na sua conta
                </Link>
              </NextLink>
            </Typography>
          </Box>
        </Grid>
      )}

      <Copyright />

      {showErrorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </Grid>
  );
};

export default Register;
