import { useRouter } from 'next/router';
import {
  Icon,
  themes,
  ListProducts,
} from '@2ndmarket/components';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Routine } from '../../components';
import Copyright from '../../components/Copyright/Copyright';

import Products from '../../helper/products';

interface Props {
  email: string;
}

const ProductSelect: React.FC<Props> = ({ email }) => {
  const router = useRouter();

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        paddingTop={8}
        display={'flex'}
        position="relative"
        flexDirection={'column'}
        justifyContent={'space-between'}
        sx={{ backgroundColor: 'background.neutral' }}
      >
        <Box paddingX={3}>
          <Typography
            component="h2"
            variant="h2"
            mb={2}
            color="text.primary"
          >
            Escolha um produto
          </Typography>
          <Typography
            component="p"
            variant="body1"
            mb={1}
            color="text.secondary"
          >
            Você está logado com o e-mail:
          </Typography>
          <Chip
            label={email}
            onClick={() => router.push('/logout')}
            onDelete={() => router.push('/logout')}
            deleteIcon={<Icon name="close" size={10} />}
            sx={{
              borderRadius: '2px',
              mb: '18px',
              color: themes.authentication.palette.primary.main,
              backgroundColor:
                themes.authentication.palette.grey[200],
              '& .MuiChip-deleteIcon': {
                padding: '0 8px',
                color:
                  themes.authentication.palette.primary.main,
                '&:hover': {
                  color:
                    themes.authentication.palette.primary.main,
                },
              },
            }}
          />
          <ListProducts
            products={Products}
            selectedColor="grey.400"
          />
          <Button
            fullWidth
            type="button"
            color="secondary"
            variant="contained"
            sx={{ marginBottom: 4, boxShadow: 'none' }}
            onClick={() => router.push('/logout')}
          >
            Sair da conta
          </Button>
        </Box>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default ProductSelect;
