import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Page500Props } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Page500 = ({
  imageWidth,
  imageHeight,
  image,
  title,
  mailto,
  description,
}: Page500Props) => {
  const router = useRouter();

  return (
    <Container maxWidth="sm" component="section">
      <Grid
        mt={20}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Icon
          name="2nd"
          size={28}
          color={themes.authentication.palette.primary.main}
        />
        <Box>
          <Image
            alt=""
            src={image}
            width={imageWidth}
            height={imageHeight}
          />
        </Box>
      </Grid>
      <Grid
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box>
          <Typography
            mt={3}
            variant="h2"
            component="h2"
            textAlign="center"
            color="text.primary"
          >
            {title}
          </Typography>
          <Typography
            mt={3}
            component="p"
            variant="body1"
            textAlign="center"
            color="text.secondary"
          >
            {description}
          </Typography>
        </Box>
      </Grid>
      <Grid display="flex" justifyContent="center" mb={5}>
        <Button
          color="primary"
          variant="contained"
          sx={{ marginTop: 4 }}
          onClick={() => router.push('/')}
        >
          Voltar para o início
        </Button>
      </Grid>
      <Grid display="flex" justifyContent="center">
        <Link href={mailto}>
          <Typography
            component="p"
            variant="body1"
            color="text.secondary"
          >
            Preciso de ajuda
          </Typography>
        </Link>
      </Grid>
    </Container>
  );
};

export default Page500;
