import { useState } from 'react';
import { Routine } from '../../components';

import Grid from '@mui/material/Grid';
import EmailSent from './EmailSent';
import Register from './Register';

const Registration: React.FC = () => {
  const [page, setPage] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      {page === 'sent' ? (
        <EmailSent email={registerEmail} setPage={setPage} />
      ) : (
        <Register
          setRegisterEmail={setRegisterEmail}
          setPage={setPage}
        />
      )}
    </Grid>
  );
};

export default Registration;
