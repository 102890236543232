import { useState } from 'react';
import { useRouter } from 'next/router';

import { Icon, themes } from '@2ndmarket/components';
import { useMe, userLogout } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Routine, Copyright, Loader } from '../../components';

const Logout: React.FC = ({}) => {
  const { data: me } = useMe();
  const router = useRouter();

  const [loader, setLoader] = useState(false);

  const handleLogout = () => {
    setLoader(true);

    userLogout();
    router.push('/');
  };

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        paddingTop={8}
        display="flex"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ backgroundColor: 'background.neutral' }}
      >
        {loader ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Loader loaderState={loader} title="Deslogando..." />
          </Box>
        ) : (
          <Box paddingX={3}>
            <IconButton
              onClick={() => router.back()}
              sx={{ ml: -1 }}
            >
              <Icon
                size={20}
                rotate="180deg"
                name="arrow-alternative"
                color={themes.authentication.palette.grey[600]}
              />
            </IconButton>
            <Typography
              component="h2"
              variant="h2"
              mt={1}
              color="text.primary"
            >
              Sair da conta
            </Typography>
            <Typography
              color="text.secondary"
              variant="body1"
              component="p"
              mt={1}
              mb={1}
            >
              Tem certeza que deseja sair da conta{' '}
              <Typography
                component="span"
                color={
                  themes.authentication.palette.primary.main
                }
              >
                {me.email}
              </Typography>
              ?
            </Typography>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={() => handleLogout()}
            >
              Confirmar e sair
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              sx={{ marginTop: 3, boxShadow: 'none' }}
              onClick={() => router.back()}
            >
              Cancelar
            </Button>
          </Box>
        )}
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Logout;
