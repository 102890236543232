import { useRouter } from 'next/router';
import { ExternalUrls } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

interface Props {
  email: string;
  setFirstPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Recovered: React.FC<Props> = ({ email, setFirstPage }) => {
  const router = useRouter();

  return (
    <Box>
      <IconButton
        onClick={() => setFirstPage(true)}
        sx={{ ml: -1 }}
      >
        <Icon
          rotate="180deg"
          name="arrow-alternative"
          color={themes.authentication.palette.grey[600]}
        />
      </IconButton>
      <Typography
        mt={1}
        mb={2}
        variant="h2"
        component="h2"
        color="text.primary"
      >
        Recupere seu e-mail
      </Typography>
      <Typography
        mb={4}
        component="p"
        variant="body1"
        color="text.secondary"
      >
        Seu e-mail cadastrado na plataforma é:{' '}
        <Typography
          component="span"
          variant="body1"
          sx={{ color: `primary.main` }}
        >
          {email}
        </Typography>
      </Typography>
      <Button
        fullWidth
        type="button"
        color="primary"
        variant="contained"
        sx={{ marginBottom: 1, position: 'relative', zIndex: 2 }}
        onClick={() => router.push('/')}
      >
        Realizar login
      </Button>
      <Button
        fullWidth
        type="button"
        component={Link}
        color="secondary"
        variant="contained"
        sx={{
          boxShadow: 'none',
          position: 'relative',
          zIndex: 1,
        }}
        href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
      >
        Fale com o suporte
      </Button>
    </Box>
  );
};

export default Recovered;
